<template>
  <div>
    <b-modal
      v-model="isShow"
      id="modal-xl"
      size="xl"
      no-close-on-backdrop
      :title="$t('support.title')"
      scrollable
    >
    <!-- Support form -->
    <div style="padding: 0px 20px 0 20px !important">
      <!-- Content register stated -->
      <div style="text-align: left">
        <span style="font-size: 14px">{{ $t("support.contentSupport") }}</span>
      </div>
      <!-- Description -->
      <div class="mt-3">
        <textarea
          class="form-control"
          style="font-size: 14px"
          :placeholder="$t('support.enterContentSupport')"
          rows="3"
          v-model="contentSupport"
        ></textarea>
      </div>
    </div>
    <!--  -->
    <!-- Choose meeting -->
    <div style="margin-top: 20px; padding: 0 20px 0 20px !important">
      <!-- Content register stated -->
      <div style="text-align: left">
        <span style="font-size: 14px">{{ $t("support.chooseMeeting") }}</span>
      </div>
      <!-- Description -->
      <div class="mt-3">
        <b-form-select
          v-model="roomMeetingSelected"
          :options="options"
          class="meeting-font-medium"
          @change="onChangeVenue(roomMeetingSelected)"
          :disabled="supportID!=0"
        >
        </b-form-select>
      </div>
    </div>
    <!--  -->
    <div style="margin: 20px 0px; padding: 0 20px 0 20px !important">
      <!-- Content register stated -->
      <div style="text-align: left">
        <span style="font-size: 14px">{{ $t("support.selectGroup") }}</span>
      </div>
      <!-- Description -->
      <div class="mt-3">
        <b-form-select
          v-model="bandGroupSelect"
          :options="bandGroupInEventList"
          class="meeting-font-medium"
          :disabled="supportID!=0"
        >
        </b-form-select>
      </div>
    </div>
    <!--  -->
    <template v-slot:modal-footer>
      <b-button 
        size="sm" 
        variant="light" 
        @click="isShow=false" 
        style="background-color: #fff; color: #000;"
        class="mr-4"
      >
        {{ $t('common.close' ) }}
      </b-button>
      <b-button
        variant="danger"
        class="btn submit-button float-right"
        style="font-size: small"
        @click="onSaveSupport"
      >
        {{ $t("support.sendRequest") }}
      </b-button>
    </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Enum from '../../enum/enum'
export default {
  data: ()=>({
    isShow: false,
    roomMeetingSelected: 1,
    contentSupport: "",
    options: [],
    supportCalendarUI: false,
    bandGroupInEventList: [],
    bandGroupSelect: null,
    supportID: 0
  }),
  watch:{
    GET_VENUE_LIST_DATA() {
      this.options = [];
      this.GET_VENUE_LIST_DATA.forEach(data => {
        let item = {
          value: {
            venue_id: data.id,
            event_id: data.event.id
          },
          text: data.venue_name,
        };
        this.options.push(item);
      });
      if (this.supportCalendarUI){
        this.roomMeetingSelected = null;
      }else{
        this.roomMeetingSelected = this.options[0].value;
      }
    },
    GET_BAND_GROUP_IN_EVENT_DATA: function(){
      this.bandGroupInEventList = []
      this.GET_BAND_GROUP_IN_EVENT_DATA.forEach(data=>{
        let item = {
          value: data.id,
          text: data.group_name
        }
        this.bandGroupInEventList.push(item)
      })
    }
  },
  computed:{
    ...mapGetters(['GET_VENUE_LIST_DATA', 'GET_BAND_GROUP_IN_EVENT_DATA'])
  },
  methods:{
    onShowModalAddNew: function(){
      this.isShow=true
      this.contentSupport = ''
      this.supportID = 0
    },
    onChangeVenue(data){
      if (data != undefined){
        let venue_filter = {
          params:{
            event: data.event_id,
            privilegeGroupList: [Enum.privilegeEnum.Support]
          }
        }
        this.GET_SUPPORT_LIST(venue_filter)
        this.GET_BAND_GROUP_IN_EVENT(venue_filter)
      }
    },
    onSaveSupport() {
      if(this.roomMeetingSelected == "" || this.roomMeetingSelected == null){
        this.ON_SHOW_TOAST({
          message: this.$t('common.inputFieldNull') + " " + this.$t('support.chooseMeeting'),
          styleType: "danger"
        });
      }else if (this.contentSupport == "" || this.contentSupport == null) {
        this.ON_SHOW_TOAST({
          message: this.$t('common.inputFieldNull') + " " + this.$t("support.contentSupport"),
          styleType: "danger"
        });
      }else if(this.bandGroupSelect == null || this.bandGroupSelect == ''){
        this.ON_SHOW_TOAST({
          message: this.$t('common.inputFieldNull') + " " + this.$t("attendance.group.title"),
          styleType: "danger"
        })
      }
      else {
        let data = {
          id: this.supportID,
          venue: this.roomMeetingSelected.venue_id,
          contentSupport: this.contentSupport,
          event: this.roomMeetingSelected.event_id,
          bandGroup: this.bandGroupSelect
        };
        if(this.supportID == 0){
          this.CREATE_SUPPORT(data)
            .then(
              function() {
                this.isShow = false;
                this.contentSupport = "";
                let filter = {
                  params: {
                    event: this.roomMeetingSelected.event_id
                  }
                };
                this.GET_SUPPORT_LIST(filter);
                this.ON_SHOW_TOAST({
                  message: "Success",
                  styleType: "success"
                });
              }.bind(this)
            )
            .catch(
              function(error) {
                this.ON_SHOW_TOAST({
                  message: error.response.data.message,
                  styleType: "danger"
                });
              }.bind(this)
            );
        }else{
          this.UPDATE_SUPPORT(data)
            .then(
              function() {
                this.isShow = false;
                this.contentSupport = "";
                let filter = {
                  params: {
                    event: this.roomMeetingSelected.event_id
                  }
                };
                this.GET_SUPPORT_LIST(filter);
                this.ON_SHOW_TOAST({
                  message: "Success",
                  styleType: "success"
                });
              }.bind(this)
            )
            .catch(
              function(error) {
                this.ON_SHOW_TOAST({
                  message: error.response.data.message,
                  styleType: "danger"
                });
              }.bind(this)
            );
        }
      }
    },
    updateSupportContent: function(data){
      this.isShow = true;
      this.supportID = data.id
      this.contentSupport = data.content;
      this.bandGroupSelect = data.support_group
      this.roomMeetingSelected = {
        venue_id: data.venue_id,
        event_id: data.event_id
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST', 
      'GET_VENUE_LIST', 
      'GET_SUPPORT_LIST', 
      'GET_BAND_GROUP_IN_EVENT', 
      'CREATE_SUPPORT', 
      'UPDATE_SUPPORT'
    ])
  },
  created(){
    if (this.$route.path == '/guide-calendar'){
      this.supportCalendarUI = true;
      let venue_filter = {
        params:{
          venue_user_list: true
        }
      }
      this.GET_VENUE_LIST(venue_filter)
    }
    else{
      let event_id = sessionStorage.getItem("event_id");
      this.event_id = event_id;
      let filter = {
        params: {
          event: event_id,
          privilegeGroupList: [Enum.privilegeEnum.Support]
        }
      };
      this.GET_VENUE_LIST(filter);
      this.GET_BAND_GROUP_IN_EVENT(filter)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.submit-button {
  width: 158px;
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%) !important;
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%) !important;
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%) !important;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  ) !important;
  color: #ffffff;
  border: 1px solid red;
}
// input:focus {
//   outline: none;
// }
.support-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}
.select {
  background-color: #eceff0;
}
.not-select {
  border-bottom: 1px solid #c2c3c3;
}