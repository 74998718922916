<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-6 p-0" style="overflow-y: auto; height: 93vh;">
        <div class="border-bottom p-3 text-left">
          <button
            type="button"
            class="btn"
            style="padding: 5px 10px 5px 10px; border-radius: 5px; border: 1px solid #26BA50; color: #26BA50; cursor: pointer;"
            @click="onShowAddNewSupport"
          ><img src="../../assets/icons/Plus.svg"/>
            <span class="pl-2">{{ $t('support.addNewSupport') }}</span>
          </button>
        </div>
        <!-- Support request data -->
        <div class="" :style="showSupport!=0 ? 'border-top:1px solid #c2c3c3':''">
          <div class="text-left" v-for="(item, index) in supportList" :key="item+index">
            <div
              :class="showSupport==index ? 'select support-title':'not-select support-title'"
              @click="showInfoSupport(index, item)"
            >
              <!-- Support title container -->
              <div style="display: flex;">
                <!-- Avatar -->
                <!-- <div
                style="padding: 20px; background-color: #fff; border-radius: 50%;"
                ></div>-->
                <avatar
                  v-if="item.user_create.url_avatar"
                  :src="item.user_create.url_avatar"
                  backgroundColor="#fff"
                  color="#000"
                  :size="40"
                  style="align-self: start;"
                ></avatar>
                <avatar
                  v-else
                  :username="item.user_create.fullname"
                  :backgroundColor="index==showSupport?'#ffffff':'#eceff0'"
                  color="#000"
                  :size="40"
                  style="align-self: start;"
                ></avatar>
                <!-- Title -->
                <div style="align-self: center; margin-left: 20px;">
                  <span style="font-size: 16px;">{{item.content}}</span>
                </div>
              </div>
              <!-- Arrow -->
              <div style="align-self: center;">
                <i style="color: #3F51B5;" class="fas fa-chevron-right"></i>
              </div>
            </div>
            <!-- Support detail -->
            <div v-if="showSupport==index" class="pb-3 not-select">
              <div class="mt-2" style="margin-left: 80px;">
                <!-- Position -->
                <div style="width: 450px;">
                  <div style="float: left; width: 100px;">
                    <span class="meeting-font-medium">{{ $t('support.location') }}</span>
                  </div>
                  <div style="width: 350px;">
                    <span class="meeting-font-medium">{{item.venue}}</span>
                  </div>
                </div>
                <!-- Time -->
                <div style="width: 350px;">
                  <div style="float: left; width: 100px;">
                    <span class="meeting-font-medium">{{ $t('support.time') }}</span>
                  </div>
                  <div style="width: 350px;">
                    <span class="meeting-font-medium">{{item.date_created}}</span>
                  </div>
                </div>
                <!-- Status -->
                <div style="width: 350px;">
                  <div style="float: left; width: 100px;">
                    <span class="meeting-font-medium">{{ $t('support.status') }}</span>
                  </div>
                  <div style="width: 350px;">
                    <span
                      class="meeting-font-medium"
                      style="color: #26BA50"
                    >{{ $t(item.status.text) }}</span>
                  </div>
                </div>
              </div>
              <!-- Button bottom -->
              <div class="mt-2" style="margin-left: 80px;">
                <span
                  class="meeting-font-small"
                  style="padding: 5px 10px 5px 10px; border-radius: 5px; border: 1px solid #26BA50; color: #26BA50; cursor: pointer;"
                  @click="onClickSendMesseger($t('support.completed'))"
                >{{ $t('support.completed') }}</span>
                <span
                  class="meeting-font-small ml-2"
                  style="padding: 5px 10px 5px 10px; border-radius: 5px; border: 1px solid #006AD4; color: #006AD4; cursor: pointer;"
                  @click="onUpdateSupportContent(item)"
                >{{ $t('support.editContent') }}</span>
                <span
                  class="meeting-font-small ml-2"
                  style="padding: 5px 10px 5px 10px; border-radius: 5px; border: 1px solid #F2404D; color: #F2404D; cursor: pointer;"
                  @click="closeRequestSupport(item)"
                >{{ $t('support.closeRequest') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="clear: both;"></div>
      <div
        class="col-sm-12 col-md-6 p-0"
        style="background-color: #ECEFF0; height: auto; border-left: 1px solid #7D7D7D; overflow-y: auto; height: 93vh; position: relative;"
      >
        <div style="overflow-y: auto; height: 100%;">
          <div v-for="(supportChatData, index) in supportChat" :key="supportChatData+index">
            <div
              class="text-left"
              style="display: flex; padding: 20px !important; width: 100%"
              v-if="supportChatData.user_info.id==supportChatData.user_create.id"
            >
              <!-- Avatar -->
              <div>
                <avatar
                  v-if="supportChatData.user_info.url_avatar"
                  :src="supportChatData.user_info.url_avatar"
                  backgroundColor="#fff"
                  color="#000"
                  :size="40"
                  style="align-self: start;"
                ></avatar>
                <avatar
                  v-else
                  :username="supportChatData.user_info.fullname"
                  backgroundColor="#fff"
                  color="#000"
                  :size="40"
                  style="align-self: start;"
                ></avatar>
              </div>
              <!-- Message -->
              <div
                style="align-self: center; margin-right: 10px; padding: 5px 10px 5px 10px; background-color: #fff; border-radius: 5px; margin-left: 10px;"
              >
                <span
                  style="display: block; color: #7D7D7D;"
                  class="meeting-font-medium"
                >{{supportChatData.user_info.fullname}}</span>
                <span class="meeting-font-medium">{{supportChatData.content}}</span>
              </div>
            </div>
            <div
              v-else
              class="text-right mt-2"
              style="padding: 20px !important; float: right; display: flex; clear: both;"
            >
              <!-- Message -->
              <div
                style="align-self: center; margin-right: 10px; padding: 5px 10px 5px 10px; background-color: #26BA50; border-radius: 5px; color: #fff;"
              >
                <span class="meeting-font-medium">{{supportChatData.content}}</span>
              </div>
              <!-- Avatar -->
              <div>
                <avatar
                  v-if="supportChatData.user_info.url_avatar"
                  :src="supportChatData.user_info.url_avatar"
                  backgroundColor="#26BA50"
                  color="#fff"
                  :size="40"
                  style="align-self: start;"
                ></avatar>
                <avatar
                  v-else
                  :username="supportChatData.user_info.fullname"
                  backgroundColor="#26BA50"
                  color="#fff"
                  :size="40"
                  style="align-self: start;"
                ></avatar>
              </div>
            </div>
          </div>
        </div>
        <div style="clear: both;"></div>
        <div
          style="display: flex; margin-top: 20px; background-color: #fff; position: absolute; width: 100%; bottom: 0;"
        >
          <!-- Like icon -->
          <div style="margin-left: 20px; align-self: center; cursor: pointer;" @click="onClickSendMesseger($t('support.thanksMessages'))">
            <img src="../../assets/icons/like.svg" width="20px;" />
          </div>
          <div style="width: 100%;">
            <input
              type="text"
              :placeholder="$t('support.supportContentChat')"
              style="width: 100%; border: none; padding: 20px;"
              class="meeting-font-medium"
              v-model="contentSupportChat"
              v-on:keyup.enter="postSupportChat"
            />
          </div>
          <!-- Send icon -->
          <div
            style="float: right; margin-right: 20px; align-self: center; cursor: pointer;"
            @click="postSupportChat"
          >
            <img src="../../assets/icons/send.svg" width="20px;" />
          </div>
        </div>
      </div>
    </div>
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
    <support-add-new-modal ref="SupportAddNewModal"></support-add-new-modal>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import { mapActions, mapGetters } from "vuex";
import dateUtils from "utils/dateUtils";
import stringUtils from "utils/stringUtils";
import SupportAddNewModal from "./SupportAddNewModal"

export default {
  components: {
    avatar: Avatar,
    'support-add-new-modal': SupportAddNewModal
  },
  data: () => ({
    roomMeetingSelected: 1,
    options: [],
    contentSupport: "",
    event_id: 0,
    supportList: [],
    showSupport: 0,
    contentSupportChat: "",
    supportSelect: "",
    supportChat: [],
    supportCalendarUI: false
  }),
  watch: {
    GET_VENUE_LIST_DATA() {
      this.options = [];
      this.GET_VENUE_LIST_DATA.forEach(data => {
        let item = {
          value: {
            venue_id: data.id,
            event_id: data.event.id
          },
          text: data.venue_name,
        };
        this.options.push(item);
      });
      if (this.supportCalendarUI){
        this.roomMeetingSelected = null;
      }else{
        this.roomMeetingSelected = this.options[0].value;
      }
    },
    GET_SUPPORT_LIST_DATA() {
      this.supportList = [];
      this.GET_SUPPORT_LIST_DATA.forEach(data => {
        let fullname =
          data.user_create.last_name + " " + data.user_create.first_name;
        let status = stringUtils.SupportStatus.filter(
          x => x.value === data.status
        ).map(x => x)[0].text;
        let item = {
          id: data.id,
          user_create: {
            id: data.user_create.id,
            fullname: fullname,
            url_avatar: data.user_create.url_avatar
          },
          venue: data.venue.name,
          venue_id: data.venue.id,
          content: data.content,
          status: {
            value: data.status,
            text: status
          },
          date_created: dateUtils.formatDate(
            data.date_created,
            dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
            "HH:mm DD/MM/YYYY"
          ),
          support_group: data.support_group,
          event_id: data.event
        };
        this.supportList.push(item);
      });
      this.showInfoSupport(
        this.showSupport,
        this.supportList[this.showSupport]
      );
    },
    GET_SUPPORT_CHAT_DATA() {
      this.supportChat = [];
      this.GET_SUPPORT_CHAT_DATA.forEach(data => {
        let item = {
          id: data.id,
          content: data.content,
          user_info: data.user_info,
          user_create: data.user_create
        };
        this.supportChat.push(item);
      });
    }
  },
  computed: {
    ...mapGetters([
      "GET_VENUE_LIST_DATA",
      "GET_SNACK_BAR",
      "GET_SUPPORT_LIST_DATA",
      "GET_SUPPORT_CHAT_DATA"
    ])
  },
  methods: {
    showInfoSupport(index, item) {
      this.showSupport = index;
      this.supportSelect = item != null ? item.id : null;
      let support_id = {
        params: {
          support_id: this.supportSelect
        }
      };
      this.GET_SUPPORT_CHAT(support_id);
    },
    postSupportChat() {
      if(this.supportSelect == '' || this.supportSelect == null){
        this.ON_SHOW_TOAST({
          message: this.$t('common.inputFieldNull') + ' ' + this.$t("support.title"),
          styleType: "danger"
        });
      }
      else if (this.contentSupportChat == "" || this.contentSupportChat == null) {
        this.ON_SHOW_TOAST({
          message: this.$t('common.inputFieldNull') + ' ' + this.$t("support.supportContentChat"),
          styleType: "danger"
        });
      } else {
        let data = {
          support_id: this.supportSelect,
          content: this.contentSupportChat
        };
        this.POST_SUPPORT_CHAT(data)
          .then(
            function() {
              this.contentSupportChat = "";
              let data = {
                params: {
                  event: this.roomMeetingSelected.event_id
                }
              };
              this.GET_SUPPORT_LIST(data);
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success"
              });
            }.bind(this)
          )
          .catch(
            function(error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger"
              });
            }.bind(this)
          );
      }
    },
    onClickSendMesseger(contentData){
      let data = {
        support_id: this.supportSelect,
        content: contentData
      };
      this.POST_SUPPORT_CHAT(data)
        .then(
          function() {
            this.contentSupportChat = "";
            let data = {
              params: {
                event: this.roomMeetingSelected.event_id
              }
            };
            this.GET_SUPPORT_LIST(data);
            this.ON_SHOW_TOAST({
              message: "Success",
              styleType: "success"
            });
          }.bind(this)
        )
        .catch(
          function(error) {
            this.ON_SHOW_TOAST({
              message: error.response.data.message,
              styleType: "danger"
            });
          }.bind(this)
        );
    },
    onChangeVenue(data){
      if (data != undefined){
        let venue_filter = {
          params:{
            event: data.event_id
          }
        }
        this.GET_SUPPORT_LIST(venue_filter)
      }
    },
    onShowAddNewSupport: function(){
      this.$refs.SupportAddNewModal.onShowModalAddNew()
    },
    onUpdateSupportContent: function(data){
      this.$refs.SupportAddNewModal.updateSupportContent(data)
    },
    closeRequestSupport: function(data){
      this.CLOSE_REQUEST_SUPPORT(data)
        .then(
          function() {
            this.isShow = false;
            this.contentSupport = "";
            let filter = {
              params: {
                event: this.roomMeetingSelected.event_id
              }
            };
            this.GET_SUPPORT_LIST(filter);
            this.ON_SHOW_TOAST({
              message: "Success",
              styleType: "success"
            });
          }.bind(this)
        )
        .catch(
          function(error) {
            this.ON_SHOW_TOAST({
              message: error.response.data.message,
              styleType: "danger"
            });
          }.bind(this)
        );
    },
    ...mapActions([
      "GET_VENUE_LIST",
      "CREATE_SUPPORT",
      "ON_SHOW_TOAST",
      "GET_SUPPORT_LIST",
      "POST_SUPPORT_CHAT",
      "GET_SUPPORT_CHAT",
      "CLOSE_REQUEST_SUPPORT"
    ])
  },
  mounted() {
    if (this.$route.path == '/guide-calendar'){
      this.supportCalendarUI = true;
      let venue_filter = {
        params:{
          venue_user_list: true
        }
      }
      this.GET_VENUE_LIST(venue_filter)
    }
    else{
      let event_id = sessionStorage.getItem("event_id");
      this.event_id = event_id;
      let filter = {
        params: {
          event: event_id
        }
      };
      this.GET_VENUE_LIST(filter);
      this.GET_SUPPORT_LIST(filter);
    }
  }
};
</script>
<style scoped>
/* input:focus {
  outline: none;
} */
.support-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}
.select {
  background-color: #eceff0;
}
.not-select {
  border-bottom: 1px solid #c2c3c3;
}
</style>
